body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-heading {
  text-align: center;
}

.main-heading h2 {
  margin-top: 50px;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 40px;
  position: relative;
  margin-bottom: 70px;
  text-transform: capitalize;
}

.main-heading h2::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  background-color: #333;
  bottom: -30px;
  width: 120px;
}

.main-heading h2::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #333;
  bottom: -38px;
  background-color: white;
}

